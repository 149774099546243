const initDecoratedHeading = () => {
  const headings = Array.from(document.querySelectorAll('.js-decorated-heading mark'))

  // eslint-disable-next-line no-useless-return
  if (!headings.length) return

  headings.map((heading) => {
    const spanElement = document.createElement('span')
    const text = heading.textContent
    heading.textContent = ''
    spanElement.textContent = text
    heading.appendChild(spanElement)
  })
}

export default initDecoratedHeading
