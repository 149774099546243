import 'slick-slider'

const initCasesSlider = () => {
  const sliders = jQuery('.js-cases-slider')
  if (!sliders.length) return

  sliders.each(function () {
    const slider = jQuery(this)
    const slides = slider.find('.slide')
    const slidesLength = slides.length
    let arrows = []

    const getSlideWidth = () => {
      const slide = jQuery(slider.find('.slick-slide')[0])
      const slideWidth = slide.css('width')
      slider.css('--width', slideWidth)
    }

    slider.on('init', function (event, slick) {
      arrows = jQuery(slider).find('.slick-arrow')

      const currSlide = slider.find('.slick-current')
      const image = currSlide.find('.case-item__image')
      setTimeout(function () {
        setOpenerPosition(image)
      }, 300)
      getSlideWidth()
    })

    const setOpenerPosition = (img) => {
      const imageHeight = img.outerHeight() / 2

      arrows.each(function () {
        const self = this

        setTimeout(function () {
          jQuery(self).css('top', imageHeight + 'px')
        }, 300)
      })
    }

    slider.slick({
      dots: false,
      infinite: true,
      speed: 300,
      variableHeight: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: '<button class="slick-prev"><span class="icon-arrow-left"></span></button>',
      nextArrow: '<button class="slick-next"><span class="icon-arrow-right"></span></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }).on('afterChange', function (event, slick, currentSlide, nextSlide) {
      const currSlide = slider.find('.slick-current')
      const image = currSlide.find('.case-item__image')
      setTimeout(function () {
        setOpenerPosition(image)
      }, 150)
      $('.slick-current-clone-animate', slider).removeClass('slick-current-clone-animate')
      $('.slick-current-clone-animate', slider).removeClass('slick-current-clone-animate')
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var
        direction
      var slideCountZeroBased = slick.slideCount - 1

      if (nextSlide === currentSlide) {
        direction = 'same'
      } else if (Math.abs(nextSlide - currentSlide) === 1) {
        direction = (nextSlide - currentSlide > 0) ? 'right' : 'left'
      } else {
        direction = (nextSlide - currentSlide > 0) ? 'left' : 'right'
      }

      // Add a temp CSS class for the slide animation (.slick-current-clone-animate)
      if (direction === 'right') {
        $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', slider).addClass('slick-current-clone-animate')
      }

      if (direction === 'left') {
        $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', slider).addClass('slick-current-clone-animate')
      }
    })

    $(window).resize(function () {
      const currSlide = slider.find('.slick-current')
      const image = currSlide.find('.case-item__image')
      arrows = jQuery(slider).find('.slick-arrow')
      setTimeout(function () {
        setOpenerPosition(image)
        getSlideWidth()
      }, 300)
    })
  })
}

export default initCasesSlider
