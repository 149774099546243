/* eslint-disable */
import '../plugins/tickerPlugin';

const initMarquee = () => {
  const holders = jQuery('.ticker-holder');

  if (!holders.length) return;

  $('.carouselTicker-start').carouselTicker({
    direction: 'next',
  });
};

export default initMarquee;
