/* eslint-disable */
import { CountUp } from 'countup.js'

const initCountUp = () => {
  const items = Array.from(document.querySelectorAll('.js-count-up'))
  const activeClass = 'in-viewport'

  if (!items.length) return

  items.map((item) => {
    const target = item.getAttribute('data-start')
    const parent = item.closest('.case-statistic')
    var numAnim = new CountUp(item, target);

    let observer = new IntersectionObserver(function(entries, observer) {
      if(entries[0].isIntersecting === true) {
        numAnim.start();
        parent.classList.add(activeClass);
      }
    });

    observer.observe(item);
  })
}

export default initCountUp
