/* eslint-disable */
import 'slick-slider'

const initPartnersAnimation = () => {
  const parents = Array.from(document.querySelectorAll('.location-section'));
  const activeClass = 'active';

  if(!parents.length) return;

  parents.map((parent) => {
    const pins = Array.from(parent.querySelectorAll('.location-pins__item'));
    const controls = parent.querySelector('.location-nav');
    const switchers = Array.from(controls.querySelectorAll('.location-nav__link'));
    const slider = jQuery(controls)

    slider.slick({
      dots: false,
      arrows: false,
      slidesToShow: 7,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      vertical: true,
      rows: 1,
      verticalSwiping: false,
      cssEase: 'linear'
    }).on('afterChange', function (event, slick, currentSlide, nextSlide) {
      const currSlide = slider.find('.slick-current')
      const switcher = currSlide.find('.location-nav__link')
      const target = jQuery(switcher.attr('href'));

      hideAllSlides();
      target.addClass(activeClass);
    });

    // hide all slides
    const hideAllSlides = () => {
      pins.map((pin) => {
        pin.classList.remove(activeClass);
      })

      switchers.map((switcher) => {
        switcher.closest('div').classList.remove(activeClass);
      })
    }

    const handleClickOnSlide = () => {
      const slides = slider.find('.slick-slide');

      slides.each(function() {
        jQuery(this).on('click', function() {
          const currentSlide = slides.index(jQuery(this));
          slider.slick('slickGoTo', currentSlide - 7);
        });
      })
    }

    handleClickOnSlide();
  })
};

export default initPartnersAnimation;
