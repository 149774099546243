/* eslint-disable */

const initPartnersAnimation = () => {
  const parents = Array.from(document.querySelectorAll('.js-animated-partners'));
  const activeClass = 'active';

  if(!parents.length) return;

  parents.map((parent) => {
    const slides = Array.from(parent.querySelectorAll('.js-partner-slide'));
    const controls = parent.querySelector('.js-controls');
    if (!controls) return
    const switchers = Array.from(controls.querySelectorAll('.partners-slider-controls__item'));
    let prevIndex = 0

    switchers.map((switcher) => {
      const clickHandler = (e) => {
        const index = switchers.indexOf(switcher);
        const target = slides[index];
        hideAllSlides();
        switcher.classList.add(activeClass);
        target.classList.add(activeClass);

        controlsAnimation(switcher, index);

        prevIndex = index;
      }

      switcher.addEventListener('click', clickHandler);
    })

    //controls animation
    const controlsAnimation = (activeItem, index) => {
      const itemHeight = activeItem.offsetHeight;
      const offsetTarget = itemHeight * index;
      controls.style.marginTop = `-${offsetTarget}px`;
    }

    // hide all slides
    const hideAllSlides = () => {
      slides.map((slide) => {
        slide.classList.remove(activeClass);
      })

      switchers.map((switcher) => {
        switcher.classList.remove(activeClass);
      })
    }

    // set first item active by default
    const setDefaultState = () => {
      slides[0].classList.add(activeClass)
      switchers[0].classList.add(activeClass);
    }

    setDefaultState();
  })
};

export default initPartnersAnimation;
