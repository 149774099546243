import lottie from 'lottie-web'
const initLogoAnimation = () => {
  var svgContainer = document.getElementById('logo')
  var svgFooterContainer = document.getElementById('footer-logo')
  var svgContainerPartners = document.getElementById('animated-logo')

  /* eslint-disable */
  var animItem = lottie.loadAnimation({
    container: svgContainer, // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: window.location.origin + '/wp-content/themes/eneon/src/inc/eneon-logo.json'
  })

  var footerLogo = lottie.loadAnimation({
    container: svgFooterContainer, // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: window.location.origin + '/wp-content/themes/eneon/src/inc/eneon-logo.json'
  })

  let observer = new IntersectionObserver(function(entries, observer) {
    entries[0].isIntersecting === true ? footerLogo.play() : '';
  });

  observer.observe(document.querySelector('#footer'));


  if(svgContainerPartners) {
    var animatedLogo = lottie.loadAnimation({
      container: svgContainerPartners, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: window.location.origin + '/wp-content/themes/eneon/src/inc/eneon-logo.json'
    })

    let observer2 = new IntersectionObserver(function(entries, observer) {
      entries[0].isIntersecting === true ? animatedLogo.play() : '';
    });

    observer2.observe(document.querySelector('.partners-section'));
  }
}

export default initLogoAnimation
