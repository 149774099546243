import 'slick-slider'

const initBigVisualSlider = () => {
  const sliders = jQuery('.js-big-visual-slider')
  if (!sliders.length) return

  sliders.each(function () {
    const slider = jQuery(this)
    let arrows = []

    const getSlideWidth = () => {
      const slide = jQuery(slider.find('.slick-slide')[0])
      const slideWidth = slide.css('width')
      slider.css('--width', slideWidth)
    }

    slider.on('init', function (event, slick) {
      arrows = jQuery(slider).find('.slick-arrow')

      const currSlide = slider.find('.slick-current')
      const image = currSlide.find('.slider-image')
      setTimeout(function () {
        setOpenerPosition(image)
      }, 300)
      getSlideWidth()
    })

    const setOpenerPosition = (img) => {
      const imageHeight = img.outerHeight() / 2

      arrows.each(function () {
        const self = this

        setTimeout(function () {
          jQuery(self).css('top', imageHeight + 'px')
        }, 300)
      })
    }

    slider.slick({
      dots: false,
      infinite: true,
      speed: 300,
      variableHeight: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            infinite: false,
            slidesToShow: 1.2,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }).on('afterChange', function (event, slick, currentSlide, nextSlide) {
      const currSlide = slider.find('.slick-current')
      const image = currSlide.find('.slider-image')
      setTimeout(function () {
        setOpenerPosition(image)
      }, 150)
    })

    $(window).resize(function () {
      const currSlide = slider.find('.slick-current')
      const image = currSlide.find('.slider-image')
      arrows = jQuery(slider).find('.slick-arrow')
      setTimeout(function () {
        setOpenerPosition(image)
        getSlideWidth()
      }, 300)
    })
  })
}

export default initBigVisualSlider
