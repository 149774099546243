import '../plugins/fixedBlockOnScroll'

function initStickyScrollBlock () {
  jQuery('#header').stickyScrollBlock({
    setBoxHeight: false,
    activeClass: 'fixed-position',
    positionType: 'fixed',
    extraTop: function () {
      var totalHeight = 0
      jQuery('100px').each(function () {
        totalHeight += jQuery(this).outerHeight()
      })
      return totalHeight
    },
    showAfterScrolled: true
  })
}

export default initStickyScrollBlock
