import './../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import './utils/vh'
/* eslint-disable */
import ResizeHandler from './utils/resize-active'
import initStickyScrollBlock from './components/initFixedHeader'
import initMobileBurgerNav from './components/initMobileBurgerNav'
import initDecoratedHeading from './components/initDecoratedHeading'
import initAOSAnimation from './components/initAOSAnimation'
import initCasesSlider from './components/initCasesSlider'
import initCasesLightNavSlider from './components/initCasesLightNavSlider'
import initBigVisualSlider from './components/initBigVisualSlider'
import initMarquee from './components/initMarquee'
import initLogoAnimation from './components/initLogoAnimation'
import initCountUp from './components/initCountUp'
import initPartnersAnimation from './components/initPartnersAnimation'
import initLocationAnimation from './components/initLocationAnimation'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin

const resizeHandler = new ResizeHandler()

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    document.documentElement.classList.add('is-loaded')
  }, 600)
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()
  initStickyScrollBlock()
  initMobileBurgerNav()
  initDecoratedHeading()
  initCasesSlider()
  initCasesLightNavSlider()
  initBigVisualSlider()
  initAOSAnimation()
  initMarquee()
  initLogoAnimation()
  initCountUp()
  initPartnersAnimation()
  initLocationAnimation()
})
