import AOS from 'aos'

const initAOSAnimation = () => {
  AOS.init({
    duration: 800,
    easing: 'ease-in-out', // default easing for AOS animations
    once: true // whether animation should happen only once - while scrolling down
  })
}

export default initAOSAnimation
