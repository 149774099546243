'use strict'

export default class MobileNav {
  constructor (holder, options) {
    const dafaultOptions = {
      activeClass: 'nav-active',
      btnOpenerSelector: '.nav-opener',
      event: 'click',
      hideOnClickOutside: false,
      outSideSelector: '',
      outsideClickEvent: ['click', 'touchstart', 'pointerdown']
    }

    this.options = typeof options === 'object' ? { ...dafaultOptions, ...options } : dafaultOptions
    this.holder = holder

    this.openerEventHandler = this.openerEventHandler.bind(this)
    this.handlerEventOutSide = this.handlerEventOutSide.bind(this)
  }

  init () {
    if (!this.holder || !!this.holder.MobileNav) return

    this.holder.MobileNav = this
    this.findElements()
    this.attachEvents()
    this.makeCallBack('onInit')
  }

  findElements () {
    this.page = document.querySelector('html')
    this.btnOpeners = this.holder.querySelectorAll(this.options.btnOpenerSelector)
    this.outSideContainer = this.options.outSideSelector ? this.holder.querySelector(this.options.outSideSelector) : false
    this.outSideContainer = this.outSideContainer ? this.outSideContainer : this.holder
  }

  attachEvents () {
    this.btnOpeners.forEach(element => {
      element.addEventListener(this.options.event, this.openerEventHandler)
    })
  }

  openerEventHandler (e) {
    e.preventDefault()
    this.toggle()
  }

  ifOpened () {
    return this.holder.classList.contains(this.options.activeClass)
  }

  show () {
    this.holder.classList.add(this.options.activeClass)
    this.makeCallBack('onShow')
    if (this.options.hideOnClickOutside) {
      this.addHendlerEventOutSide()
    }
  }

  hide () {
    this.holder.classList.remove(this.options.activeClass)
    this.makeCallBack('onHide')
    if (this.options.hideOnClickOutside) {
      this.removeHendlerEventOutSide()
    }
  }

  handlerEventOutSide (e) {
    let ifOpener = false
    this.btnOpeners.forEach(element => {
      if (element === e.target) {
        ifOpener = true
      } else if (e.target.closest(this.options.btnOpenerSelector) === element) {
        ifOpener = true
      }
    })
    if (this.ifOpened() && !this.outSideContainer.contains(e.target) && !ifOpener) this.hide()
  }

  addHendlerEventOutSide () {
    this.options.outsideClickEvent.forEach(event => {
      this.page.addEventListener(event, this.handlerEventOutSide)
    })
  }

  removeHendlerEventOutSide () {
    this.options.outsideClickEvent.forEach(event => {
      this.page.removeEventListener(event, this.handlerEventOutSide)
    })
  }

  toggle () {
    this.ifOpened() ? this.hide() : this.show()
  }

  makeCallBack (functionName, ...environs) {
    if (typeof this.options[functionName] === 'function') {
      this.options[functionName](environs.length ? environs : this)
    }
  }

  destroy () {
    if (!this.holder || !this.holder.MobileNav) return

    this.btnOpeners.forEach(element => {
      element.removeEventListener(this.options.event, this.openerEventHandler)
    })

    this.hide()
    this.removeHendlerEventOutSide()
    this.holder.MobileNav = ''
    this.makeCallBack('onDestroy')
  }
}
